<template>
	<div class="classification">
		<div class="classification-T">
			<div class="classification-TL">
				<Breadcrumb class="bre"></Breadcrumb>
			</div>
			<div class="classification-TR">
				<div class="classification-searchsMai">
					<el-button type="danger" icon="el-icon-plus" @click="dialogVisible1 = true">新增</el-button>
				</div>
			</div>
		</div>
		<div class="classification-B">
			<div class="classification2">
				<el-table :data="tableData" border style="width: 100%" size="small">
					<template slot="empty">
						<noData></noData>
					</template>
					<el-table-column prop="T_uuid" label="编号" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="T_name" label="传感器分类名" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<div style="display: flex;">
								<div class="tbodyTd-czs1" @click="setCz('a',scope.row)">
									<el-button type="primary" icon="el-icon-search" size="small">绑定传感器</el-button>
								</div>
								<div class="tbodyTd-czs1" @click="setCz('b',scope.row)" style="margin: 0 10px;">
									<el-button type="warning" icon="el-icon-search" size="small">编辑</el-button>
								</div>
								<div class="tbodyTd-czs1" @click="setCz('c',scope.row)">
									<el-button type="danger" icon="el-icon-search" size="small">删除</el-button>
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="classification-pagination">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page="pages.page" :page-size="pages.page_z" layout="total, sizes, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog title="设备绑定" :visible.sync="dialogVisible" :close-on-click-modal="false" :append-to-body="true">
			<div class="NewUser" style="overflow: auto;">
				<el-tabs tab-position="top" v-model="activeName1" @tab-click="handleClick">
					<el-tab-pane label="设备库" name="2">
						<div class="classification-mod">
							<div class="classification-mod-search">
								<div class="classification-mod-search-L"
									style="display: flex;align-items: center;margin-bottom: 10px;">
									<div class="classification-mod-search-L1" style="margin:10px 10px 10px 0;">
										<el-input placeholder="请输入内容查询" @keyup.enter.native="sousuo"
											v-model="DeviceFrom.T_name" size="small" clearable>
										</el-input>
									</div>
									<div class="classification-mod-search-L1">
										<el-button type="primary" icon="el-icon-search" size="small" @click="sousuo">搜索
										</el-button>
									</div>
								</div>
								<div class="classification-mod-search-R">
									<!-- 右边内容 -->
								</div>
							</div>
							<div class="classification-mod-cont">
								<el-table :data="tableDatas2" stripe style="width: 100%" border size="mini">
									<template slot="empty">
										<noData></noData>
									</template>
									<el-table-column prop="T_devName" label="主机名" width="180"
										:show-overflow-tooltip="true">
									</el-table-column>
									<el-table-column prop="T_sn" label="SN" width="180" :show-overflow-tooltip="true">
									</el-table-column>
									<el-table-column label="操作" :show-overflow-tooltip="true">
										<template slot-scope="scope">
											<el-button type="primary" icon="el-icon-link" size="small"
												@click="setbang(scope.row)">绑定
											</el-button>
										</template>
									</el-table-column>
								</el-table>
							</div>
							<div class="classification-mod-pag" style="margin-top: 10px;">
								<el-pagination layout="total, sizes, prev, pager, next, jumper" :total="totalDev"
								@size-change="handleSizeChange1"
								@current-change="handleCurrentChange1"
									:hide-on-single-page="hideSig">
								</el-pagination>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="已绑定设备" name="1">
						<div class="classification-mod">
							<div class="classification-mod-cont">
								<el-table :data="tableDatas1" stripe style="width: 100%;" height="450px" border size="mini">
									<template slot="empty">
										<noData></noData>
									</template>
									<el-table-column prop="T_name" label="主机名" width="180" :show-overflow-tooltip="true">
									</el-table-column>
									<el-table-column prop="T_sn" label="SN" width="200" :show-overflow-tooltip="true">
									</el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<el-button type="danger" icon="el-icon-delete" size="small" @click="bingDel(scope.row)">删除</el-button>
										</template>
										
									</el-table-column>
								</el-table>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
				<el-dialog title="传感器库" :visible.sync="innerVisible" :append-to-body="true">
					<div class="innerVisiblesk">
						<div style="display: flex;align-items: center;padding-bottom: 20px;">
							<div style="margin-right: 10px;">
								<el-button type="primary" icon="el-icon-folder-opened" size="small" @click="Fanxuan(tableData2)">反选
								</el-button>
							</div>
							<div class="innerVisiblesk-tit1">
								<el-button type="primary" icon="el-icon-link" size="small" @click="AllSelect">批量绑定
								</el-button>
							</div>
						</div>
						<div style="overflow-y: auto;">
							<el-table ref="multipleTable" v-loading="tabload" border :data="tableData2" height="450"
								tooltip-effect="dark" style="width: 100%">
								<template slot="empty">
									<noData></noData>
								</template>
								<el-table-column type="selection" width="55">
								</el-table-column>
								<el-table-column label="传感器名" :show-overflow-tooltip="true">
									<template slot-scope="scope">{{ scope.row.T_name }}</template>
								</el-table-column>
								<el-table-column prop="T_sn" label="SN" :show-overflow-tooltip="true">
								</el-table-column>
								<el-table-column prop="address" label="操作">
									<template slot-scope="scope">
										<el-button type="primary" icon="el-icon-link" size="small"
											@click="Bingd(scope.row)">绑定
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</el-dialog>
			</div>
		</el-dialog>
		<el-dialog title="新增传感器分类" :visible.sync="dialogVisible1" width="30%" :close-on-click-modal="false"
			:append-to-body="true">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px">
				<el-form-item label="分类名称" prop="T_name">
					<el-input v-model="form.T_name"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" :loading="btnLoad" @click="onSubmit('form')">{{btntxt}}</el-button>
					<el-button @click="dialogVisible1 = false">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-drawer title="编辑" :visible.sync="drawer" size="50%" :append-to-body="true">
			<div class="drawers">
				<div class="drawers-tit">
					<div class="drawers-tit1">
						传感器分类名称
					</div>
					<el-input v-model="EditData.T_name" placeholder="请输入内容"></el-input>
				</div>
				<div class="drawers-pir">
					<el-tabs v-model="activeName">
						<el-tab-pane label="通知规则" name="first">
							<div class="ovhide">
								<el-table :data="tableData3" border size="mini" style="width: 100%">
									<template slot="empty">
										<noData></noData>
									</template>
									<el-table-column label="报警类型" width="120">
										<template slot-scope="scope">
											<div>
												{{scope.row[0]}}
											</div>
										</template>
									</el-table-column>
									<el-table-column label="记录" width="50">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[1]" true-label="1" false-label="0"></el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="处理" width="50">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[2]" true-label="1" false-label="0"></el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="微信" width="50">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[3]" true-label="1" false-label="0"></el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="触发数" width="60">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[4]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="上限数" width="60">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[5]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="短信" width="50">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[6]" true-label="1" false-label="0"></el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="触发数" width="60">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[7]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="上限数" width="60">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[8]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="手机" width="50">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[9]" true-label="1" false-label="0"></el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="触发数" width="60">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[10]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="上限数" width="60">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[11]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="邮箱" width="50">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[12]" true-label="1" false-label="0"></el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="触发数" width="60">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[13]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="上限数" width="60">
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[14]">
											</div>
										</template>
									</el-table-column>
								</el-table>
								<div style="margin-top: 10px;">
									<el-button type="primary" size="mini" style="">重置数据</el-button>
									<el-button type="primary" size="mini" style="margin-left: 10px;">说明</el-button>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="微信通知" name="second">
							<div class="ovhide" style="display: flex;">
								<div style="flex: 1;border: 1px solid #ccc;display: flex;align-items: center;justify-content: center;flex-direction: column;">
									<!-- <vue-qr :logoSrc="imageUrl" :text="EditData.T_QRCode" :size="200"></vue-qr> -->
									<div style="width: 200px;height: 200px;">
										<img :src="EditData.T_QRCode" style="width: 100%;height: 100%;">
									</div>
									<div style="color: #606266;font-size: 14px;">让被通知者，微信扫一扫，有效期7天</div>
								</div>
								<div style="flex: 1;border: 1px solid #ccc;border-left: none;padding: 20px;">
									<div v-for="(item,index) in tableData4" :key="index" style="margin-bottom: 10px;">
										<div style="display: flex;align-items: center;justify-content: space-between;" v-if="item!=''">
											<div style="display: flex;align-items: center;">
												<div style="width: 25px;height: 25px;margin-right: 10px;">
													<img src="../../assets/img/wx.png" alt="" srcset="" style="width: 100%;height: auto;">
												</div>
												<div style="" :title="item.name">
													<input type="text" v-model="item.name" class="inputPir" @blur="blurPir" @focus="focusPir">
												</div>
											</div>
											<div style="display: flex;align-items: center;">
												<div @click="Delpir(item,'vxtz')" style="font-size: 12px;text-decoration: underline;cursor: pointer;">删除</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="微信通知(冷链)" name="third">
							<div class="ovhide" style="display: flex;">
								<div
									style="flex: 1;border: 1px solid #ccc;display: flex;align-items: center;justify-content: center;flex-direction: column;">
									<!-- <vue-qr :logoSrc="imageUrl" :text="EditData.T_QRCode2" :size="200"></vue-qr> -->
									<img :src="EditData.T_QRCode2" alt="" srcset="" style="width: 200px;height: 200px;">
									<div style="color: #606266;font-size: 14px;">让被通知者，微信扫一扫，有效期7天</div>
								</div>
								<div style="flex: 1;border: 1px solid #ccc;border-left: none;padding: 20px;">
									<div v-for="(item,index) in tableData5" :key="index" style="margin-bottom: 10px;">
										<div style="display: flex;align-items: center;justify-content: space-between;" v-if="item!=''">
											<div style="display: flex;align-items: center;">
												<div style="width: 25px;height: 25px;margin-right: 10px;">
													<img src="../../assets/img/wx.png" alt="" srcset="" style="width: 100%;height: auto;">
												</div>
												<div style="" :title="item.name">
													<input type="text" v-model="item.name" class="inputPir" @blur="blurPir">
												</div>
											</div>
											<div style="display: flex;align-items: center;">
												<div @click="Delpir(item,'vxtz-LL')" style="font-size: 12px;text-decoration: underline;cursor: pointer;">删除</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="短信通知" name="fourth">
							<div class="ovhide" style="display: flex;">
								<div
									style="flex: 1;border: 1px solid #ccc;display: flex;flex-direction: column;align-items: center;justify-content: center;">
									<div style="margin-top: 10px;height: 40px;display: flex;align-items: center;">
										<el-input v-model="inputValue1" placeholder="添加需短信通知的号码" style="margin-right: 10px;">
										</el-input>
										<el-button type="primary" icon="el-icon-plus" @click="Addinput1">添加</el-button>
									</div>
								</div>
								<div
									style="flex: 1;padding: 20px;border: 1px solid #ccc;border-left: none;overflow-y: auto;">
									<div style="padding-bottom:10px;display: flex;align-items: center;justify-content: space-between;"
										v-for="(item,index) in tableData6" :key="index">
										<div style="display: flex;align-items: center;">
											<div style="margin-right: 5px;">
												<i class="el-icon-chat-line-square" style="font-size: 18px;"></i>
											</div>
											<div>
												{{item}}
											</div>
										</div>
										<div @click="Delpir(item,'dxtz')" style="font-size: 12px;text-decoration: underline;cursor: pointer;">删除
										</div>
									</div>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="电话通知" name="third1">
							<div class="ovhide" style="display: flex;">
								<div
									style="flex: 1;border: 1px solid #ccc;display: flex;flex-direction: column;align-items: center;justify-content: center;">
									<div style="margin-top: 10px;height: 40px;display: flex;align-items: center;">
										<el-input v-model="inputValue2" placeholder="添加需电话通知的号码" style="margin-right: 10px;">
										</el-input>
										<el-button type="primary" icon="el-icon-plus" @click="Addinput2">添加</el-button>
									</div>
								</div>
								<div
									style="flex: 1;padding: 20px;border: 1px solid #ccc;border-left: none;overflow-y: auto;">
									<div style="padding-bottom:10px;display: flex;align-items: center;justify-content: space-between;"
										v-for="(item,index) in tableData7" :key="index">
										<div style="display: flex;align-items: center;">
											<div style="margin-right: 5px;">
												<i class="el-icon-mobile-phone" style="font-size: 18px;"></i>
											</div>
											<div>
												{{item}}
											</div>
										</div>
										<div @click="Delpir(item,'dhtz')" style="font-size: 12px;text-decoration: underline;cursor: pointer;">删除
										</div>
									</div>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane label="邮箱通知" name="fourth1">
							<div class="ovhide" style="display: flex;">
								<div
									style="flex: 1;border: 1px solid #ccc;display: flex;flex-direction: column;align-items: center;justify-content: center;">
									<div style="margin-top: 10px;height: 40px;display: flex;align-items: center;">
										<el-input v-model="inputValue3" placeholder="添加需邮件通知的号码" style="margin-right: 10px;">
										</el-input>
										<el-button type="primary" icon="el-icon-plus" @click="Addinput3">添加</el-button>
									</div>
								</div>
								<div
									style="flex: 1;padding: 20px;border: 1px solid #ccc;border-left: none;overflow-y: auto;">
									<div style="padding-bottom:10px;display: flex;align-items: center;justify-content: space-between;"
										v-for="(item,index) in tableData8" :key="index">
										<div style="display: flex;align-items: center;">
											<div style="margin-right: 5px;">
												<i class="el-icon-message" style="font-size: 18px;"></i>
											</div>
											<div>
												{{item}}
											</div>
										</div>
										<div @click="Delpir(item,'yxtz')" style="font-size: 12px;text-decoration: underline;cursor: pointer;">删除
										</div>
									</div>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div style="margin: 50px auto 0 auto;">
					<el-button type="primary" @click="btnSub()">提交（提交后生效）</el-button>
				</div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
	// import vueQr from 'vue-qr'
	import {
		ClassList,
		ClassAdd,
		ClassDel,
		DevSenList, //已绑定传感器
		DeviceList, //设备库
		ClassGet,
		ClassBind_Add,
		ClassBind_Del,
		ClassEdit
	} from '../../api/equipmentManagement/classification.js'
	import {
		showLoading,hideLoading
	} from '../../utils/loading-pir.js'
	import defaultImage from '@/assets/logo.png'
	export default {
		components: {
			// vueQr
		},
		data() {
			return {
				defaultImage,
				timer:null,//定时器
				EditId:null,//编辑获取数据T_id
				EditData:{},//编辑的提交数据包
				BingData: {
					T_class_id: null,
					T_sn: null,
					T_id: null,
				},
				tabload: true,
				hideSig: false,
				totalDev: 0,
				btntxt: '立即添加',
				btnLoad: false,
				DevSenFrom: { //已绑定设备
					User_uuid: '',
					T_calssid: '',
					page: 1,
					page_z: 99999
				},
				DevSenFrom1: { //点击设备库进去后的列表
					T_sn: '',
					page: 1,
					page_z: 99999
				},
				DeviceFrom: {
					User_uuid: '',
					T_name: '',
					T_monitor: '',
					T_online: '',
					page: 1,
					page_z: 7,
				},
				total: 0,
				pages: {
					T_name: '',
					page: 1,
					page_z: 10,
				},

				imageUrl: require("../../assets/img/bzdLOGO.png"), //icon路径,
				drawer: false,
				input: '',
				inputValue1:'',
				inputValue2:'',
				inputValue3:'',
				dialogVisible1: false,
				dialogVisible: false,
				innerVisible: false,
				loading: false,
				multipleSelection: [],
				form: {
					T_name: '',
				},
				rules: {
					T_name: [{
						required: true,
						message: '请输入传感器分类名称',
						trigger: 'blur'
					}],
				},
				activeName1: '2',
				activeName: 'first',
				
				tableData3: [],//分类编辑框1
				tableData4: [],//微信通知列表
				tableData5: [],//微信通知冷链
				tableData6: [],//短信通知
				tableData7: [],//电话通知
				tableData8: [],//邮箱通知
				
				tableData2: [],
				tableDatas1: [],
				tableDatas2: [],
				tableData: [],
			}
		},
		beforeDestroy() {
		    clearInterval(this.timer);
		},
		watch:{
			drawer(news){
				var that = this
				if(news){
					this.timer = setInterval( async ()=>{
						var Edit = await that.GetEditApi()
						////console.log('参数变化',Edit)
						that.tableData4 = []
						var obj1 = Edit.data.Data.T_Notice_wx.split('|')//微信通知
						obj1.forEach(function(i){
							if(i!=''){
								that.tableData4.push({
									keys:i.split('/')[0],
									name:i.split('/')[1]
								})
							}
						})
					},2000);
				}else{
					clearInterval(this.timer)
				}
			}
		},
		mounted() {
			this.GetClassListApi()
		},
		methods: {
			Delpir(item,msgs){
				var that = this
				switch(msgs) {
				     case 'vxtz':
				        ////console.log('微信通知',item,this.tableData4)
						var arr = []
						this.tableData4.forEach(function(obj,index){
							////console.log(obj.keys===item.keys)
							if(obj.keys===item.keys){
								that.tableData4.splice(index, 1)
							}else{
								arr.push(obj.keys +'/'+ obj.name)
							}
						})
						////console.log('删除完',arr)
						that.EditData.T_Notice_wx = arr.join('|')+'|'
						////console.log('删除玩',that.EditData)
				        break;
				     case 'vxtz-LL':
				       ////console.log('微信通知-冷链',item,msgs)
				        break;
					case 'dxtz':
						that.tableData6.forEach(function(obj,index){
							if(obj===item){
								that.tableData6.splice(index, 1)
							}
						})
						this.EditData.T_Notice_message = that.tableData6.length===0?'':that.tableData6.join('|')+'|'
					   break;
					case 'dhtz':
					  that.tableData7.forEach(function(obj,index){
					  	if(obj===item){
					  		that.tableData7.splice(index, 1)
					  	}
					  })
					  this.EditData.T_Notice_phone = that.tableData7.length===0?'':that.tableData7.join('|')+'|'
					   break;
				     default:
						 that.tableData8.forEach(function(obj,index){
							if(obj===item){
								that.tableData8.splice(index, 1)
							}
						 })
						 this.EditData.T_Notice_mailbox = that.tableData8.length===0?'':that.tableData8.join('|')+'|'
				       ////console.log('邮箱通知',item,msgs)
				} 
			},
			Addinput1(){//短信通知添加
				if(this.inputValue1===''){
					this.$message.error('请输入正确手机号')
				}else{
					////console.log('前',this.EditData.T_Notice_message)
					this.tableData6.push(this.inputValue1)
					this.EditData.T_Notice_message = this.EditData.T_Notice_message===undefined?this.inputValue1+'|':this.EditData.T_Notice_message + this.inputValue1+'|'
					////console.log('短信通知添加',this.EditData,this.tableData6,this.inputValue1)
				}
			},
			Addinput2(){//电话通知添加
				if(this.inputValue2===''){
					this.$message.error('请输入正确手机号')
				}else{
					this.tableData7.push(this.inputValue2)
					this.EditData.T_Notice_phone = this.EditData.T_Notice_phone===undefined?this.inputValue2+'|':this.EditData.T_Notice_phone + this.inputValue2+'|'
				}
			},
			Addinput3(){//邮箱通知添加
				if(this.inputValue3===''){
					this.$message.error('请输入正确邮箱')
				}else{
					this.tableData8.push(this.inputValue3)
					this.EditData.T_Notice_mailbox = this.EditData.T_Notice_mailbox===undefined?this.inputValue3+'|':this.EditData.T_Notice_mailbox + this.inputValue3+'|'
				}
			},
			blurPir(){//微信通知编辑重命名
				var arr = []
				this.tableData4.forEach(function(obj){
					arr.push(obj.keys +'/'+ obj.name)
				})
				this.EditData.T_Notice_wx = arr.join('|')+'|'
				this.btnSub()
				this.timer
			},
			focusPir(){
				////console.log('停止')
				 clearInterval(this.timer);
			},
			btnSub(){//提交处理通知通知规则的所有框
				var arr = []
				this.tableData3.forEach(function(j){
					arr.push(j.join(','))
				})
				this.EditData.T_Notice_mechanism = arr.join('|')+'|'
				////console.log('提交',this.EditData)
				this.SetClassEditApi()
			},
			SetClassEditApi(){//编辑提交
				this.$set(this.EditData,'T_id',this.EditId)
				ClassEdit(this.EditData).then(res=>{
					////console.log('编辑成功',res)
					if(res.data.Code===200 && res.data.Msg==='ok!'){
						this.$message.success('编辑成功')
						this.GetClassListApi()
						this.drawer = false
					}
				})
			},
			GetEditApi(){//分类列表编辑获取数据Api
				return new Promise(resolve => {
					ClassGet({T_id:this.EditId}).then(function(res) {
						resolve(res);
					})
				});
			},
			bingDel(e){ //删除绑定
				var that = this
				this.$confirm('此操作将执行删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					that.BingData.T_id = e.T_id
					that.BingData.T_sn = e.T_sn
					that.SetBingDel()
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
			SetBingDel() {
				var that = this
				ClassBind_Del(this.BingData).then(function(res) {
					if(res.data.Code===200 && res.data.Msg==="ok!"){
						that.$message.success('删除成功')
						that.GETDevSenListApi()
					}
				})
			},
			
			
			async Bingd(e){//单个绑定
				////console.log(e)
				this.BingData.T_id = e.T_id
				this.BingData.T_sn = e.T_sn
				var j = await this.SetBing()
				////console.log('e',j)
				if(j.data.Code===200 && j.data.Msg==="ok!"){
					this.$message.success('绑定成功')
				}
			},
			//反选
			Fanxuan(rows) {
				////console.log(rows)
				if(rows){
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				}else{
					this.$message.error('没有可选择数据')
					return
				}
			},
			//批量绑定按钮
			async AllSelect() {
				var selectData = this.$refs.multipleTable.selection //已勾选
				var num = 0
				var suc = 0//成功
				var err = 0//失败
				if (selectData.length == 0) {
					this.$message.error('请选择至少一项，在进行批量绑定')
					return
				}else{
					showLoading()
					for (let i = 0; i < selectData.length; i++) {
						this.BingData.T_id = selectData[i].T_id
						this.BingData.T_sn = selectData[i].T_sn
						var j = await this.SetBing()
						if(j.data.Code===200 && j.data.Msg==="ok!"){
							suc = suc+1
						}else{
							err = err+1
						}
						if(num+1==selectData.length){
							this.$message.success(`批量绑定完成,成功(${suc}),失败(${err})`)
							hideLoading()
						}else{
							num = num+1
						}
					}
				}
			},
			SetBing() {
				return new Promise(resolve => {
					setTimeout(() => {
						ClassBind_Add(this.BingData).then(function(res) {
							resolve(res);
						})
					}, 500)
				});
			},
			GetClassListApi() {
				var that = this
				ClassList(that.pages).then(res => {
					////console.log('传感器列表', res)
					if (res.data.Code === 200 && res.data.Msg === 'ok!') {
						that.tableData = res.data.Data.Data
						that.total = res.data.Data.Num
					}
				})
			},
			async setCz(i, obj) {
				////console.log(obj)
				var that = this
				if (i === 'a') { //绑定传感器
					////console.log('处理', i)
					this.BingData.T_class_id = obj.Id
					this.dialogVisible = true
					this.DevSenFrom.User_uuid = obj.T_uuid
					this.DevSenFrom.T_calssid = obj.Id
					this.DeviceFrom.User_uuid = obj.T_uuid
					this.GETDeviceListApi()
				} else if (i === 'b') { //编辑
					this.drawer = true
					this.EditId = obj.Id
					var Edit = await this.GetEditApi()
					if(Edit.data.Code===200&&Edit.data.Msg==="ok!"){
						that.tableData3 = []
						that.tableData4 = [] //微信通知
						that.tableData5 = [] //微信通知冷链
						that.tableData6 = [] //短信通知
						that.tableData7 = [] //电话通知
						that.tableData8 = [] //邮箱通知
						that.EditData = Edit.data.Data
						// var ppp = 'o5EKB1buEEsyDP6u-6H3H326T4no/重令名1|o5EKB1dVz-izXkZYKJZGOYuDCSWE/重令名2|'
						var arr = Edit.data.Data.T_Notice_mechanism.split('|')//通知规则
						var arr1 = Edit.data.Data.T_Notice_wx.split('|')//微信通知
						// var arr1 =ppp.split('|')//微信通知
						
						var arr2 = Edit.data.Data.T_Notice_wx2.split('|')//微信通知冷链
						var arr3 = Edit.data.Data.T_Notice_message.split('|')//短信通知
						var arr4 = Edit.data.Data.T_Notice_phone.split('|')//电话通知
						var arr5 = Edit.data.Data.T_Notice_mailbox.split('|')//邮箱通知
						arr.forEach(function(item){//通知规则
							if(item!=''){
								var ob = item.split(',')
								that.tableData3.push(ob)
							}
						})
						arr1.forEach(function(item){//微信通知
							if(item!=''){
								var ob  = {
									keys:item.split('/')[0],
									name:item.split('/')[1]
								}
								that.tableData4.push(ob)
							}
						})
						arr2.forEach(function(item){//微信通知
							////console.log()
							if(item!=''){
								var ob  = {
									keys:item.split('/')[0],
									name:item.split('/')[1]
								}
								that.tableData5.push(ob)
							}
						})
						arr3.forEach(function(item){//微信通知
							if(item!=''){
								that.tableData6.push(item)
							}
						})
						arr4.forEach(function(item){//微信通知
							if(item!=''){
								that.tableData7.push(item)
							}
						})
						arr5.forEach(function(item){//微信通知
							if(item!=''){
								that.tableData8.push(item)
							}
						})
					}
				} else if (i === 'c') { //同步大屏
					////console.log('删除', i)
					this.$confirm('此操作将执行删除, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
						center: true
					}).then(() => {
						ClassDel({
							T_id: obj.Id
						}).then(res => {
							////console.log('删除', res)
							if (res.data.Code === 200 && res.data.Msg === "ok!") {
								that.pages.page = 1
								that.GetClassListApi()
								setTimeout(function() {
									this.$message.success('传感器分类列表删除成功！')
								}, 200)
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},
			GETDevSenListApi() {
				DevSenList(this.DevSenFrom).then(res => {
					////console.log('已经绑定', res)
					if (res.data.Code === 200 && res.data.Msg == "ok!") {
						this.tableDatas1 = res.data.Data.Data
					}
				})
			},
			GETDeviceListApi() {
				DeviceList(this.DeviceFrom).then(res => {
					////console.log('设备列表', res)
					if (res.data.Code === 200 && res.data.Msg == "ok!") {
						this.tableDatas2 = res.data.Data.Device_lite
						this.totalDev = res.data.Data.Num
						if (res.data.Data.Page_size === 1) {
							this.hideSig = true
						} else {
							this.hideSig = false
						}
					}
				})
			},
			sousuo() {
				this.GETDeviceListApi()
			},
			handleClick() {
				if (this.activeName1 == 1) {
					this.GETDevSenListApi()
				} else {
					this.GETDeviceListApi()
				}
			},
			setbang(e) {
				////console.log('绑定', e)
				this.DevSenFrom1.T_sn = e.T_sn
				this.innerVisible = true
				this.tableData2 = []
				this.tabload = true
				DevSenList(this.DevSenFrom1).then(res => {
					////console.log('绑定列表', res)
					if (res.data.Code === 200 && res.data.Msg == "ok!") {
						this.tableData2 = res.data.Data.Data
					}
					this.tabload = false
				})
			},
			handleSizeChange(e) { //每页多少条
				this.pages.page_z = e
				this.GetClassListApi()
			},
			handleCurrentChange(e) { //跳转页
				this.pages.page = e
				this.GetClassListApi()
			},
			handleSizeChange1(e) { //设备库每页多少条
				this.DeviceFrom.page_z = e
				this.GETDeviceListApi()
			},
			handleCurrentChange1(e) { //设备库跳转页
				this.DeviceFrom.page = e
				this.GETDeviceListApi()
			},
			onSubmit(formName) {
				var that = this
				this.$refs[formName].validate((valid) => {
					if (valid) {
						that.btnLoad = true
						that.btntxt = '进行中'
						ClassAdd(that.form).then(res => {
							if (res.data.Code === 200 && res.data.Msg === "ok!") {
								that.pages.page = 1
								that.GetClassListApi()
								that.dialogVisible1 = false
								that.$message.success('新增传感器分类列表')
							}
							that.btnLoad = false
							that.btntxt = '立即添加'
						})
					} else {
						return false;
					}
				});
			},
		}
	}
</script>

<style lang="scss">
	.drawers {
		color: #31353e;
		margin: 0 20px;

		.drawers-tit {
			.drawers-tit1 {
				margin-bottom: 15px;
				font-size: 14px;
				color: #606266;
			}
		}
		.inputPir:valid{//失去焦点
			border:none;
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis; 
			white-space: nowrap;
			border:1px solid #fff;
			border-radius: 2px;
			padding: 5px;
		}
		.inputPir:focus{//获得焦点
			outline: none;
			border:1px solid #ccc;
			border-radius: 2px;
			padding: 5px;
		}
		.drawers-pir {
			margin-top: 40px;

			.ovhide {
				overflow-y: auto;
				height: 400px;
			}

			.inputValue {
				width: 30px;
				border: 1px solid #DCDFE6;
				border-radius: 3px;
			}

			.inputValue:focus {
				outline: none;
				border: 1px solid #409EFF;
			}
		}
	}

	.el-table-column--selection .cell {
		padding-left: 10px;
	}

	.el-dropdown-menu--mini .el-dropdown-menu__item {
		line-height: 35px;
		padding: 0 10px;
		font-size: 12px;
	}

	.classification {
		user-select: none;

		.classification-mod {
			border: 1px solid red;

			.classification-mod-pag {
				margin-top: 14px;
			}

			.classification-mod-cont {
				overflow-y: auto;
				height: 450px;
			}

			.classification-mod-search {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 0;
				border: 1px solid red;

				.classification-mod-search-L {
					.classification-mod-search-L1 {
						margin-right: 10px;
					}
				}
			}
		}

		.classification-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;

			.classification-TL {
				display: flex;
				align-items: center;
			}

			.classification-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}
		
		.classification-B{
			background: rgba(#fff, 1);
			margin: 10px;
			.classification2 {
				// border-radius: 10px;
				overflow: hidden;
				margin: 10px;
				padding: 20px;
			}
			
			.classification-pagination {
				padding: 20px;
				border-radius: 2px;
				display: flex;
			}
		}
	}
</style>
