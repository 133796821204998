// 传感器分类接口
import {
	axios
} from '../../utils/http.js'

const ClassApi = {
	ClassList: '/v3/Class/List',//传感器分类列表
	ClassAdd: '/v3/Class/Add',//添加传感器分类
	ClassDel: '/v3/Class/Del',//删除传感器分类
	
	DevSenList: '/v3/DeviceSensor/List',//已绑定设备列表
	DeviceList: '/v3/Device/List',//设备库
	ClassGet: '/v3/Class/Get',//编辑获取的数据包
	ClassEdit: '/v3/Class/Edit',//编辑提交的数据包
	ClassBind_Add: '/v3/Class/Bind_Add',//传感器库绑定
	ClassBind_Del: '/v3/Class/Bind_Del',//传感器库删除
}


//传感器库绑定
export function ClassEdit(data) {
	return axios({
		url: ClassApi.ClassEdit,
		method: 'post',
		data:data
	})
}
//传感器库绑定
export function ClassBind_Del(data) {
	return axios({
		url: ClassApi.ClassBind_Del,
		method: 'post',
		data:data
	})
}
//传感器库绑定
export function ClassBind_Add(data) {
	return axios({
		url: ClassApi.ClassBind_Add,
		method: 'post',
		data:data
	})
}
//编辑的数据包
export function ClassGet(data) {
	return axios({
		url: ClassApi.ClassGet,
		method: 'post',
		data:data
	})
}
//设备库
export function DeviceList(data) {
	return axios({
		url: ClassApi.DeviceList,
		method: 'post',
		data:data
	})
}
//已绑定设备列表
export function DevSenList(data) {
	return axios({
		url: ClassApi.DevSenList,
		method: 'post',
		data:data
	})
}
//传感器分类列表
export function ClassList(data) {
	return axios({
		url: ClassApi.ClassList,
		method: 'post',
		data:data
	})
}
//添加传感器分类
export function ClassAdd(data) {
	return axios({
		url: ClassApi.ClassAdd,
		method: 'post',
		data:data
	})
}
//添加传感器分类
export function ClassDel(data) {
	return axios({
		url: ClassApi.ClassDel,
		method: 'post',
		data:data
	})
}