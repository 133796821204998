import {
	Loading
} from 'element-ui';

export function showLoading(){//开启动画
	//console.log('出发')
	Loading.service({
		lock: true,
		text: 'Loading',
		spinner: 'el-icon-loading',
		background: 'rgba(0, 0, 0, 0.7)'
	});
}
export function hideLoading(){//关闭动画
	Loading.service().close()
}